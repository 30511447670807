import logo from './logo.svg';
import './normal.css'
import './App.css';
import aidalogo from './aida-logo-transparent.png';
import aidalogoplain from './aida-logo-plain-transparent.png';
import askmeanything from './askmeanything.png';
import bluemic from './bluemic.png';
import createvisual from './createvisual.png';
import profileicon from './profileicon.png';
import settingsicon from './settingsicon.png';
import uploadimage from './uploadimage.png';
import voiceinput from './voiceinput.png';
import sendbutton from './send.png';
import aidahead from './aida-head.png';
import likeicon from './like.png';
import dislikeicon from './dislike.png';


import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import { useState } from 'react';


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);



function App() {

  // add state for input and chat log
  const [input, setInput] = useState("");
  const [chatLog, setChatLog] = useState([]);
  const [error, setError] = useState("");
  const [waitloader, setWaitLoader] = useState(false);


  async function handleSubmit(e) {
    e.preventDefault();

    // check if input is empty, then return
    if (input === "") {
      return;
    }

    let chatLogNew = [...chatLog, { user: "me", message: `${input}` }];
    setInput("");
    setError("");
    setWaitLoader(true);
    setChatLog(chatLogNew);

    let data = { output: "No answers found."};
    try {
      const response = await fetch('https://aida-api.velocityclinical.com/query', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "OPTIONS,POST,GET"
        },
        body: JSON.stringify({
          query: input,
        })
      });
      data = await response.json();
    }
    catch (e) {
      console.log(e);
      setError("Error in fetching data. Please try again later.");
    }
    finally
    {
      setWaitLoader(false);
    }

    console.log({ user: "gpt", message: data });
    await setChatLog([...chatLogNew, { user: "gpt", message: data }]);
  }

  function clearChats() {
    setChatLog([]);
    setError("");
  }

  return (
    <div className="App">
      <aside className="sidemenu">
        <div className="sidemenu-logo">
          <div><img
            id="aida-logo"
            src={aidalogoplain}
            alt="aida-logo"
            height={80}
          /></div>
        </div>
        <div className="side-menu-button" onClick={clearChats}>
          <span>+</span>
          New Chat
        </div>
        <Loader />
      </aside>
      <section className="chatbox">
        <div className="chat-log-topbar">
          <div className="chat-log-topbar-settingsicon">
            <img
              id="settings-icon"
              src={settingsicon}
              alt="settings-icon"
              height={41}
              width={41}
            />
            <img
              id="profile-icon"
              src={profileicon}
              alt="profile-icon"
              height={41}
              width={41}
            />
          </div>
        </div>
        <div className="error">{error}</div>

        <ChatHeader chatLog={chatLog} />
        <div className="chat-log">
          {chatLog.map((message, index) => (
            <ChatMessage key={index} message={message} />
          ))}
        </div>
        <Loader2 visible={waitloader} /> 

        <div className="chatbox-input-holder">
          <form onSubmit={handleSubmit}>
            <input className="chatbox-input-textarea" placeholder='Type your query...' rows="1"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            &nbsp;
            <button type="submit" className="chatbox-input-button" onClick={handleSubmit}>
              &nbsp;<img
                id="send-icon"
                src={sendbutton}
                alt="send-icon"
                height={40}
                width={40}
              /></button>

            <img
              id="bluemic-icon"
              src={bluemic}
              alt="bluemic-icon"
              height={35}
              width={35}
            />
          </form>

        </div>
      </section>
    </div>
  );
}

const ChatHeader = ({ chatLog }) => {
  return (
    <div className={`chatbox-header ${chatLog.length > 0 && "chatbox-header-invisible"}`}>
      <div className="chatbox-header-title">
        <div className="aida-logo-div">
          <div className="aida-logo"><img
            id="aida-logo"
            src={aidalogo}
            alt="aida-logo"
          /></div>
        </div>
        <div className="chatbox-header-text">
          <div className="chatbox-header-common">
            <div className="chatbox-header-text-title"><img id="ama-logo" src={askmeanything} alt="ask me anything" height={25} />&nbsp; ask me anything</div>
            <br />
            <div className="chatbox-header-text-box ama">
              &nbsp;&nbsp;&nbsp;&nbsp;Get Instant help!!&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>
          <div className="chatbox-header-common">
            <div className="chatbox-header-text-title"><img id="visual-logo" src={createvisual} alt="create visuals" height={25} />&nbsp; create visuals</div>
            <br />
            <div className="chatbox-header-text-box visual">
              &nbsp;&nbsp;&nbsp;Effortless actionable insights&nbsp;&nbsp;&nbsp;
            </div>
          </div>
          <div className="chatbox-header-common">
            <div className="chatbox-header-text-title"><img id="voice-logo" src={voiceinput} alt="voice enabled input" height={25} />&nbsp; voice enabled input</div>
            <br />
            <div className="chatbox-header-text-box voice">
              &nbsp;&nbsp;&nbsp;Speak to me anything&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>
          <div className="chatbox-header-common">
            <div className="chatbox-header-text-title"><img id="upload-logo" src={uploadimage} alt="upload image" height={25} />&nbsp; upload image</div>
            <br />
            <div className="chatbox-header-text-box upload">
              &nbsp;&nbsp;&nbsp;Image based search&nbsp;&nbsp;&nbsp;
            </div>
          </div>


        </div>
      </div>
    </div>
  );
}

const ChatMessage = ({ message }) => {
  return (
    <div className={`chat-message ${message.user == "gpt" && "chatgpt"}`}>
      <div className="chat-message-center">
        <div className={`avatar ${message.user == "gpt" && "chatgpt"}`}>
          {message.user == "gpt" && <img
            id="aida-logo"
            src={aidahead}
            alt="aida-logo"
            height={41}
            width={41}
          />}
          {message.user == "me" && <img
            id="human-avatar"
            src={profileicon}
            alt="human-avatar"
            height={41}
            width={41}
          />}
        </div>
        <div className="message">
          {message.user == "me" && message.message}

          {message.user == "gpt" && message.message.output && <Infomation title="Answer" text={message.message.output}/>}<br />
          {message.user == "gpt" && message.message.graph && <ChartComponent data={message.message.graph} />}<br />
          {message.user == "gpt" && message.message.additional_info && <Infomation title="Additional Info" text={message.message.additional_info}/>}<br />
          {message.user == "gpt" && message.message.suggestions && <Infomation title="Suggestions" text={message.message.suggestions}/>}
        </div>
        <div>
          {message.user == "gpt" && <img
            id="like-icon"
            src={likeicon}
            alt="like-icon"
            height={15}
            width={15}
          />}
        </div>
        <div>
          {message.user == "gpt" &&  <img
            id="dislike-icon"
            src={dislikeicon}
            alt="dislike-icon"
            height={15}
            width={15}
          />}
        </div>
      </div>
    </div>
  );
};

/**
 * @returns velocity loader component
 */
const Loader = () => {
  return (
    <div className="loader">
      <div className="loader-text">Powered by &nbsp;</div>
      <div className="dot1"> </div>
      <div className="dot2"></div>
      <div className="dot3"></div>
    </div>
  );
};

/**
 * @returns velocity loader component
 */
const Loader2 = ({visible}) => {
  return (
    <div>
      {visible && ( 
      <div className="loader2">
        <div className="dot1 animate"> </div>
        <div className="dot2 animate"></div>
        <div className="dot3 animate"></div>
      </div>
      )}
    </div>
)};

const Infomation = ({title, text}) => {
  return (
    <div>
      <div><b>{title}:</b> {text}</div>
    </div>
  );
};

const ChartComponent = ({data}) => {
  const dataFormatted = {
    labels: data.values.map((value) => value.title),
    datasets: [
      {
        label: `${data.dataname}`,
        data: data.values.map((value) => value.value),
        backgroundColor: ['#7694EA', '#A7E4EF', '#78C280', '#EDBA85', '#758AC8', '#E4E1E1', '#DBD2A6', '#F4BCBD', '#F7EB97', '#A7E4CE', '#ADE6F8'],
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={dataFormatted} options={options} />;
};

export default App;
